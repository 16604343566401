import React from "react"
import { WPApi } from "@api"
import { TextInput, AnimatedButton} from "@components/shared"
import is from "is_js"

import {
  header,
  header__title,
  form,
  form__input,
  form__button,
  form__select,
} from "./styles/contact.module.scss"

class ContactForm extends React.Component {
  state = {
    email: "",
    subject: "",
    msg: "",
    imBusy: false,
  }

  action = () => {
    if (is.email(this.state.email) === true) {
      this.setState({
        imBusy: true,
      })
      WPApi.sendContactForm(this.state.email, this.state.subject).then(
        ({ message }) => {
          this.setState({
            email: "",
            subject: "",
            msg: message,
            imBusy: false,
          })
        }
      )
    }
  }

  render() {
    if (this.state.imBusy) {
      return (
        <div>
          <h6>Wysyłam Twoją wiadomość</h6>
        </div>
      )
    }
    if (this.state.msg !== "") {
      return (
        <div>
          <h6>{this.state.msg}</h6>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className={header}>
          <h3 className={header__title}>Skontaktuj się z nami</h3>
        </div>
        <div className={form}>
          <TextInput
            className={form__input}
            type="email"
            value={this.state.email}
            placeholder="Twój email"
            onChange={(ev) => {
              const { value } = ev.target
              this.setState({
                email: value,
              })
            }}
          />
          <div className={form__select}>
            <select
              value={this.state.subject}
              onChange={(ev) => {
                const { value } = ev.target
                this.setState({
                  subject: value,
                })
              }}
            >
              <option value="" disabled selected hidden>
                Wybierz powód kontaktu
              </option>
              <option value="Poprawienie CV">Poprawienie CV</option>
              <option value="Współpraca biznesowa">Współpraca biznesowa</option>
              <option value="Trening z mentorem">Trening z mentorem</option>
              <option value="Gladiatorzy Javascriptu">
                Gladiatorzy Javascriptu
              </option>
            </select>
          </div>
          <AnimatedButton className={form__button} onClick={this.action}>
            Wyślij
            <i className="icofont-double-right"></i>
          </AnimatedButton>
        </div>
      </React.Fragment>
    )
  }
}

export default ContactForm
