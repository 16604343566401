import React from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment/"
import "moment/locale/pl"
import { retrieveEvents } from "./logic"

import "react-big-calendar/lib/css/react-big-calendar.css"

// moment.locale("pl")

const localizer = momentLocalizer(moment)

const messages = {
  today: "Dziś",
  previous: "Poprzedni",
  next: "Następny",
  month: "Miesiąc",
  work_week: "Tydzień roboczy",
  agenda: "Spis wydarzeń",
}

class GoogleCalendar extends React.Component {
  state = {
    events: [],
  }

  componentDidMount() {
    this.getCalendarData()
  }

  getCalendarData() {
    retrieveEvents().then((data) => {
      let _events = []
      if (data.items) {
        {
          data.items.map((event) => {
            _events.push({
              start: new Date(event.start.date || event.start.dateTime),
              end: new Date(event.end.date || event.end.dateTime),
              title: event.summary,
            })
          })
        }
        this.setState({
          events: _events,
        })
      }
    })
  }



  render() {
    return (
      <div>
        <Calendar
          selectable={true}
          events={this.state.events}
          views={["month", "work_week", "agenda"]} // widoki
          min={new Date(0, 0, 0, 8, 0, 0)} // zakres godziny od
          max={new Date(0, 0, 0, 22, 0, 0)} // zakres godziny do
          step={30}
          messages={messages} // customowe przyciski
          style={{ height: 500 }}
          localizer={localizer}
          onSelectSlot={this.handleSelect}
        />
      </div>
    )
  }
}

export default GoogleCalendar
