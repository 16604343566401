import React from "react"
import { AnimatedSection } from "@components/shared"

import {
  about__option,
  about__option__wrapper,
  about__option__item,
} from "./styles/about.module.scss"

const SingleOption = ({ label, content, cta_label, cta_link }) => {
  return (
    <AnimatedSection className={about__option}>
      <div className={about__option__wrapper}>
        <div className={about__option__item}>
          <h6>{label}</h6>
        </div>
        <div className={about__option__item}>
          <h6>{content}</h6>
          {cta_label ? <a href={cta_link}>{cta_label}</a> : null}
        </div>
      </div>
    </AnimatedSection>
  )
}

export default SingleOption
